import * as React from "react";
import { useWindowScroll } from "react-use";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import Breadcrumb from "@components/Breadcrumb";
import Button from "@ui/Button";
import { graphql, useStaticQuery } from "gatsby";
import { DeepNonNullable } from "ts-essentials";
import {
  PressDataQuery,
  StrapiPressArticlesCover,
  StrapiPressAwardsCover,
} from "../../graphqlTypes";
import { GatsbyImage } from "gatsby-plugin-image";
import { HEADER_OFF_SIDEBAR } from "@utils/GlobalConstants";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody } from "@utils/mdxUtils";

const ContactCard = () => (
  <>
    <div>
      <h3>Learn more about AccuRanker’s story.</h3>
    </div>
    <span className="text-gray-800">
      For an interview or statement contact CEO Henrik Jørgensen directly at
    </span>
    <h3>
      <a href="mailto:hj@accuranker.com" className="text-accuRankerOrange-500">
        hj@accuranker.com
      </a>{" "}
      or{" "}
      <a href="tel:+4553534280" className="text-accuRankerOrange-500">
        +45 53 53 42 80
      </a>
    </h3>
    <Button color="orange">Email Us Now</Button>
    <h3>Speaking enquiries</h3>
    <span className="text-gray-800">
      If you’re interested in having someone from AccuRanker speak at your
      event, please send us an email with information on your event and topic.
    </span>
    <a href="mailto:hj@accuranker.com" className="text-accuRankerOrange-500">
      <h3>hj@accuranker.com</h3>
    </a>
    <h3>As mentioned in</h3>
    {/* Image gallery */}
    <div className="flex flex-row flex-wrap gap-9">
      <img
        className="w-2/5 object-contain"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/HubSpot_Logo.svg/2560px-HubSpot_Logo.svg.png"
      />
      <img
        className="w-2/5 object-contain"
        src="https://www.shoutmeloud.com/wp-content/uploads/2017/12/ShoutMeloud-new-Brand-Logo.png"
      />
      <img
        className="w-2/5 object-contain"
        src="https://cdn.searchenginejournal.com/wp-content/themes/sej14/images-new/press/white-2x.png"
      />
      <img
        className="w-2/5 object-contain"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/HubSpot_Logo.svg/2560px-HubSpot_Logo.svg.png"
      />
    </div>
  </>
);

interface ArticleCardInput {
  id: number;
  image: StrapiPressArticlesCover;
  text: string;
  link: string;
  linkText: string;
}

const ArticleCard = ({ id, image, text, link, linkText }: ArticleCardInput) => (
  <div
    key={id}
    className="w-84 flex flex-col gap-y-5 bg-accuRankerPurple-500 rounded-xl overflow-hidden"
  >
    <div className={"flex justify-center w-full"}>
      <GatsbyImage
        alt={""}
        image={image.localFile.childImageSharp.gatsbyImageData}
      />
    </div>
    <div className="px-8 pb-12">
      <p className="text-accuRankerOrange-500 font-medium">{text}</p>
      <a
        href={link}
        target={"_blank"}
        className={
          "bg-accuRankerOrange-500 hover:bg-accuRankerOrange-900 text-white font-medium text-center py-2.5 px-4 rounded-md inline-block w-full"
        }
      >
        {linkText}
      </a>
    </div>
  </div>
);

interface AwardCardInput {
  id: number;
  image: StrapiPressAwardsCover;
  title: string;
  description: string;
}

const AwardCard = ({ image, title, description }: AwardCardInput) => (
  <div
    className="w-84 grid grid-rows-6 text-center gap-y-5 px-8 py-12 rounded-xl overflow-hidden"
    style={{ backgroundColor: "#f8f8f8" }}
  >
    <div className="h-24 w-3/4 m-auto row-span-3">
      <GatsbyImage
        alt={""}
        image={image.localFile.childImageSharp.gatsbyImageData}
        style={{ objectFit: "contain" }}
      />
    </div>
    <h3 className="row-span-1">{title}</h3>
    <p className="text-accuRankerPurple-900 row-span-2">{description}</p>
  </div>
);

const PressPage = () => {
  const data = useStaticQuery<DeepNonNullable<PressDataQuery>>(graphql`
    query PressData {
      strapiPress {
        pageTitle
        title
        brandGuidelinesTitle
        childStrapiPressBrandGuidelinesText {
          childMdx {
            body
          }
        }
        brandGuidelinesDownloadText
        brandGuidelinesDownload {
          localFile {
            brandGuidelinesDownloadURL: publicURL
          }
        }
        articlesTitle
        articles {
          id
          title
          linkText
          link
          cover {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 192
                  layout: FIXED
                  transformOptions: { fit: COVER }
                )
              }
            }
          }
        }
        awardsTitle
        awards {
          id
          title
          description
          cover {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 270)
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageTitle,
    title,
    brandGuidelinesTitle,
    childStrapiPressBrandGuidelinesText: brandGuidelinesText,
    brandGuidelinesDownloadText,
    brandGuidelinesDownload: {
      localFile: { brandGuidelinesDownloadURL },
    },
    articlesTitle,
    articles,
    awardsTitle,
    awards,
  } = data.strapiPress;

  const { y } = useWindowScroll();

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block flex flex-col max-w-7xl mx-5 md:mx-auto px-4 sm:px-0 mt-4 mb-4">
        <Breadcrumb title={title} />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <h1 className="blogpost font-sans text-white font-semibold mt-16 mb-10">
              {title}
            </h1>
          </div>
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="max-w-7xl mx-5 md:mx-auto py-12 px-4 sm:px-0">
          <div className="max-w-7xl relative mx-auto px-4 sm:px-0 mt-4 mb-4 grid grid-cols-5 gap-10">
            <div className="col-start-1 col-end-6 lg:col-end-5">
              <div className="flex flex-col gap-y-12">
                <div>
                  <h2 className="mb-9">{brandGuidelinesTitle}</h2>
                  <p className="font-serif">
                    <MDXRenderer>{mdxBody(brandGuidelinesText)}</MDXRenderer>
                  </p>
                  <a
                    className={
                      "bg-accuRankerOrange-500 hover:bg-accuRankerOrange-900 text-white font-medium text-center py-2.5 px-4 rounded-md"
                    }
                    href={brandGuidelinesDownloadURL}
                  >
                    {brandGuidelinesDownloadText}
                  </a>
                </div>
                <div>
                  <h2 className="mb-9">{articlesTitle}</h2>
                  <div className="flex flex-row flex-wrap gap-9">
                    {articles.map(article => {
                      return (
                        <ArticleCard
                          id={article.id}
                          text={article.title}
                          link={article.link}
                          linkText={article.linkText}
                          image={article.cover as StrapiPressArticlesCover}
                        />
                      );
                    })}
                  </div>
                </div>
                <div>
                  <h2 className="mb-9">{awardsTitle}</h2>
                  <div className="flex flex-row flex-wrap gap-9">
                    {awards.map(award => {
                      return (
                        <AwardCard
                          id={award.id}
                          image={award.cover as StrapiPressAwardsCover}
                          title={award.title}
                          description={award.description}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 w-80">
              <div
                className="block"
                style={{
                  top: y > HEADER_OFF_SIDEBAR ? "120px" : "-160px",
                  position: y > HEADER_OFF_SIDEBAR ? "sticky" : "absolute",
                }}
              >
                <div className="w-80 border border-gray-200 rounded-md flex flex-col gap-y-4 bg-white p-12">
                  <ContactCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default PressPage;
